<template>
  <div class="pa-2">
    <v-card flat
    >
    <v-toolbar flat color="transparent">
        <v-toolbar-title>
            Search HS Codes
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">X</v-btn>
    </v-toolbar>
    <v-card-text>
<v-row align="center" justify="end">
      <v-col cols="12" sm="12" md="6" lg="4">
        <el-input
          suffix-icon="el-icon-search"
          v-model="params.search"
          clearable
          placeholder="Search"
        >
          <template slot="prepend">
            <el-button @click="createProduct()"
              ><i class="el-icon-plus"></i
            ></el-button>
          </template>
        </el-input>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12">
        <v-data-table
          fixed-header
          hide-default-footer
          :loading="loading"
          :items="hsCodes.data"
          :headers="headers"
          height="60vh"
          style="cursor: pointer"
          @click:row="selectCode"
          :options="{
            page: page,
            itemsPerPage: params.limit,
            pageStart: 1,
            pageStop: Math.round(hsCodes.total / params.limit),
            pageCount: Math.round(hsCodes.total / params.limit),
            itemsLength: hsCodes.total,
          }"
        >
          <template v-slot:footer>
            <v-col cols="12" class="text-center my-0 py-0">
              <el-pagination
                style="color: var(--v-primaryText-base)"
                :current-page.sync="page"
                :page-size.sync="params.limit"
                :pager-count="5"
                :page-sizes="[10, 25]"
                :layout="
                  !$vuetify.breakpoint.mobile
                    ? 'sizes, prev, pager, next, jumper, total'
                    : 'prev, pager, next'
                "
                :total="hsCodes.total"
              >
              </el-pagination>
            </v-col>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    </v-card-text>
    </v-card>
    
  </div>
</template>
<script>
export default {
  data: () => ({
    headers: [
        {
        text: "Level",
        value: "level",
        align: "center",
      },
      {
        text: "Parent",
        value: "parent",
        sortable: false,
        align: "left",
      },
      {
        text: "Code",
        value: "code",
        sortable: false,
        align: "left",
        width: '200px'
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
        align: "left",
      }
    ],
    hsCodes: {
      total: 0,
      data: [],
    },
    loading: false,
    page: 1,
    params: {
      limit: 10,
      offset: 0,
      search: null,
      section: null
    },
    searchTimer: null,
    sections: {
        total: 0,
        data:[]
    },
    subSections: {
        total: 0,
        data:[]
    }
  }),
  watch: {
    async page(value) {
      this.params.offset = (value - 1) * this.params.limit;
      await this.getHSCode();
    },
    "params.limit": {
      immediate: true,
      async handler() {
        this.page = 1;
        await this.getHSCode();
      },
    },
    "params.search": {
      async handler() {
        await this.getHSCode();
      },
    },
    // "params.section": {
    //   async handler() {
    //     await this.getHSCode();
    //   },
    // },
  },
  created(){
    this.load()
  },    
  methods: {
    async getHSCode() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.loading = true;
      this.searchTimer = setTimeout(async () => {
        this.hsCodes = await this.$API.getHSCodes({
          params: this.params
        });
        this.loading = false;
      }, 500);
    },
    async load(){
        this.sections = await this.$API.getHSCodeSections()
        // this.subSections = await this.$API.getHSCodeSubSections()
    },
    selectCode(item){
        this.$emit('select', item)
    }
  },
};
</script>
