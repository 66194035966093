var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-2"},[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v(" Search HS Codes ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("X")])],1),_c('v-card-text',[_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('el-input',{attrs:{"suffix-icon":"el-icon-search","clearable":"","placeholder":"Search"},model:{value:(_vm.params.search),callback:function ($$v) {_vm.$set(_vm.params, "search", $$v)},expression:"params.search"}},[_c('template',{slot:"prepend"},[_c('el-button',{on:{"click":function($event){return _vm.createProduct()}}},[_c('i',{staticClass:"el-icon-plus"})])],1)],2)],1)],1),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"fixed-header":"","hide-default-footer":"","loading":_vm.loading,"items":_vm.hsCodes.data,"headers":_vm.headers,"height":"60vh","options":{
            page: _vm.page,
            itemsPerPage: _vm.params.limit,
            pageStart: 1,
            pageStop: Math.round(_vm.hsCodes.total / _vm.params.limit),
            pageCount: Math.round(_vm.hsCodes.total / _vm.params.limit),
            itemsLength: _vm.hsCodes.total,
          }},on:{"click:row":_vm.selectCode},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-col',{staticClass:"text-center my-0 py-0",attrs:{"cols":"12"}},[_c('el-pagination',{staticStyle:{"color":"var(--v-primaryText-base)"},attrs:{"current-page":_vm.page,"page-size":_vm.params.limit,"pager-count":5,"page-sizes":[10, 25],"layout":!_vm.$vuetify.breakpoint.mobile
                    ? 'sizes, prev, pager, next, jumper, total'
                    : 'prev, pager, next',"total":_vm.hsCodes.total},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"update:pageSize":function($event){return _vm.$set(_vm.params, "limit", $event)},"update:page-size":function($event){return _vm.$set(_vm.params, "limit", $event)}}})],1)]},proxy:true}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }